import React, {useContext, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {BlockTitle, ComboClient, ComboMachine, OnlineHandPays, SidebarHistory} from '../../components/blocks'
import {Apis} from '../../utils/apis';
import axios from 'axios';
import {SiteContext} from '../../context/SiteContext';
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {toast} from '../../hooks/useToast';
import HisHandpay from "../historial/hisHandpay";

const HandPay = ({onlineHandpays}) => {

  const {fondo, printer, config} = useContext(SiteContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const queryClient = useQueryClient();
  const [selectedMachine, setSelectedMachine] = useState({ confId: null, machineName: '' });
  const [amount, setAmount] = useState('');

  const handPayMutation = useMutation({
    mutationKey: ['op_handpay_redeem'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-handpay', data),
    onSuccess: () => {
      setSelectedCustomer(null)
      setSelectedMachine({confId: null, machineName: ''});
      setAmount('')
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      queryClient.invalidateQueries({queryKey: ['getCurrentHandpays']});
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handlePayment = () => {
    if (selectedMachine && amount) {
      const mutationData = {
        amount: amount,
        fondoId: fondo.id,
        customerId: amount > config.customerRequiredHp ? selectedCustomer?.id : null,
        confId: selectedMachine.confId,
        handpayId: selectedMachine?.id,
        printerId: printer?.printerId
      };
      handPayMutation.mutate(mutationData);
    }
  };


  return (
    <>
      <BlockTitle title='PAGO MANUAL'/>

      <SidebarHistory
        title='Historial - Pagos manuales'
        className='mb-2'
      >
        <HisHandpay />
      </SidebarHistory>

      <div className='flex flex-row items-start flex-1 gap-4'>
        <div className='flex flex-col flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
          <div className='flex flex-row items-center gap-6'>
            <FormControl className='flex-1'>
              <label>Máquina</label>
              <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine} />
            </FormControl>
            <FormControl className='flex-1'>
              <label>Monto</label>
              <Input
                placeholder="00.0"
                type='number'
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                className='text-2xl font-semibold'
              />
            </FormControl>
          </div>
          {amount > config.customerRequiredHp &&
            <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer} />
          }
          
          <Button size='lg' onClick={handlePayment}>
            Pagar
          </Button>
        </div>
        <OnlineHandPays 
          data={onlineHandpays}
          setAmountHandPay={setAmount} 
          selectedMachine={selectedMachine} 
          setSelectedMachine={setSelectedMachine}
        />
      </div>
    </>
  )
}

export default HandPay
