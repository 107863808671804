import React, {useContext, useMemo} from 'react'
import {EmptyState, ReactTable} from '../../components/blocks'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {SiteContext} from '../../context/SiteContext'
import {format, parseISO} from 'date-fns'
import {formatNumber} from '../../utils/general'
import CardSummary from "./hisUtils";
import useEndpoint from "../../hooks/useEndpoint";
import {Button} from "../../components/atoms";
import {LuCopy} from "react-icons/lu";
import {toast} from "../../hooks/useToast";

const HisRedeemTickets = () => {

  const {fondo, config} = useContext(SiteContext);
  const hisRedeemTicket = useEndpoint("hisRedeemTicket")

  const {data, isFetched} = useQuery({
    queryKey: ['getTicketsReedem', fondo?.id],
    queryFn: async () => await axios.get(hisRedeemTicket,
      {
        params: {
          fondoId: fondo?.id,
        }
      })
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copiado",
        variant: 'info',
        duration: 1500
      })
    });
  }

  const columns = useMemo(
    () => [
      // {
      //   header: 'Estado',
      //   id: 'status',
      //   accessorFn: row => row.status ? getStatusText(row.status) : '--',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      // },
      {
        header: 'Numero de ticket',
        accessorKey: 'validNumber',
        cell: info => (
          <div className='flex flex-row items-center flex-1 gap-2'>
            <span style={{fontFamily: '"Courier New", Courier, monospace'}}>
              {info.row.original.validNumber !== null ? info.row.original.validNumber.padStart(18, '0') : '--'}
            </span>
            <Button
              size='sm'
              variant='outline'
              className='px-2'
              onClick={() => copyClipboard(info.row.original.vnFull)}
            >
              <LuCopy size={16}/>
            </Button>
          </div>
        )
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount !== null ? formatNumber(row.amount, '') : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Emitido en',
        id: 'machine',
        accessorFn: row => row.machine ? row.machine : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de creación',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Canjeado en',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha de canje',
        id: 'redeemedAt',
        accessorFn: row => `${row.redeemedAt ? format(parseISO(row.redeemedAt), "hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Cliente',
        id: 'customerName',
        accessorFn: row => row.customerName !== "  " ? row.customerName : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {(isFetched && data) &&
        <>
          <div className='flex flex-row items-center gap-4'>
            <CardSummary
              text='Nro de Operaciones'
              value={data.quantity}
            />
            <CardSummary
              text='Total'
              value={formatNumber(data.totalAmount, config.symbol)}
            />
          </div>
          {data?.data && data.data.length > 0 ?
            <ReactTable columns={columns} data={data.data} initialPageSize={50}/>
            :
            <div className="relative w-full h-full overflow-hidden">
              <EmptyState/>
            </div>
          }
        </>
      }
    </div>
  )
}

export default HisRedeemTickets
