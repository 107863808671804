import React, {useContext, useState} from 'react'
import {SiteContext} from '../../../context/SiteContext';
import {Apis} from '../../../utils/apis';
import axios from 'axios';
import {useMutation, useQuery} from '@tanstack/react-query'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input
} from '../../atoms';
import {LuChevronDown, LuUser} from 'react-icons/lu';
import {FiEdit} from 'react-icons/fi';
import {toast} from "../../../hooks/useToast";
import {FaSpinner} from "react-icons/fa";
import EmptyState from '../empty-state';
import {RiUserSearchLine} from 'react-icons/ri';

const ComboClient = ({selectedCustomer, setSelectedCustomer}) => {

  const {salaId} = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [queryV2, setQueryV2] = useState('');
  const [resultsReniec, setResultsReniec] = useState(null);
  const [loading, setLoading] = useState(false);

  const {data: searchResults = [], refetch} = useQuery({
    queryKey: ['getCustomers', query],
    queryFn: async () => {
      if (query.length >= 3) {
        setLoading(true);
        const response = await axios.get(`${Apis.SEARCH}/elastic?query=${query}`);
        if (query.length === 8 && response.data.length === 0) {
          setQueryV2(query);
          refetch2();
        }
        setResultsReniec(null);
        setLoading(false);
        return response.data;
      } else {
        setLoading(false);
        return [];
      }
    }
  })

  const {refetch: refetch2} = useQuery({
    queryKey: ['postReniec', queryV2],
    queryFn: async () => {
      if (query.length >= 3) {
        setLoading(true);
        const response = await axios.get(`${Apis.PERSON}/search/${query}`);
        setResultsReniec(response.data);
        setLoading(false);
        return response.data;
      } else {
        setLoading(false);
        return [];
      }
    }
  })

  const createCustomer = useMutation({
    mutationKey: ['create_customer'],
    mutationFn: (data) => axios.post(Apis.PC + '/customers/v2', data),
    onSuccess: (response) => {
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      console.log(response.data);
      setResultsReniec(null);
      setLoading(true);
      const customerNew = {
        id: response.data.id,
        salaId: response.data.salaId,
        document: response.data.person?.document,
        firstName: response.data.person?.firstName,
        lastName1: response.data.person?.lastName1,
        lastName2: response.data.person?.lastName2,
        fullTotalName: response.data.person?.fullTotalName,
        fullName: response.data.person?.fullName,
      }
      handleCustomerSelect(customerNew);
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    },
  });

  const getEstadoCivil = (estadoCivil) => {
    switch (estadoCivil) {
      case 'SOLTERO':
        return 'S'
      case 'CASADO':
        return 'C'
      case 'VIUDO':
        return 'V'
      case 'DIVORCIADO':
        return 'D'
      default:
        return 'N'
    }
  }

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setQuery('');
    setQueryV2('');
    setResultsReniec(null);
    setOpen(false);
  };

  const handleChangeCustomer = () => {
    setSelectedCustomer(null);
    setQuery('');
    setQueryV2('');
    setResultsReniec(null);
    setOpen(true);
    refetch();
  };

  return (
    <>
      <FormControl>
        {selectedCustomer ? (
          <div className='flex flex-row items-center'>
            <div className='flex flex-row items-center flex-1 gap-2'>
              <div className='flex items-center justify-center bg-white rounded-xl text-neutral-950 h-[50px] w-[50px]'>
                <LuUser size={24}/>
              </div>
              <div className='flex flex-col'>
                <span className='text-lg font-bold'> {selectedCustomer.fullTotalName}</span>
                <span className='text-sm'> {selectedCustomer.document}</span>
              </div>
            </div>
            <Button variant='outline' onClick={handleChangeCustomer}><FiEdit size={20} /> Cambiar</Button>
          </div>
        ) : (
          <>
            <label>Cliente</label>
            <Button
              variant="outline"
              type="button"
              className='justify-between w-full'
              size='lg'
              onClick={() => setOpen(true)}
            >
              <span className='text-lg text-neutral-500'>Buscar...</span>
              <LuChevronDown className="w-5 h-5 opacity-50 shrink-0" />
            </Button>
          </>
        )}
      </FormControl>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Seleccione un cliente</DialogTitle>
            <DialogDescription>
              Ingrese al menos 3 letras de su documento o nombre
            </DialogDescription>
          </DialogHeader>
          <Input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Buscar cliente..."
          />

          <div className='flex flex-col h-[300px] overflow-y-auto overflow-x-hidden'>
            {loading &&
              <div className={'flex justify-center items-center'}>
                <FaSpinner className='animate-spin' size={24} />
              </div>
            }
            {query.length === 0 ?
              <EmptyState 
                title='Buscar cliente' 
                subtitle='Ingrese el nombre o documento del cliente'
                icon={<RiUserSearchLine size={48}/>}
                className='h-full'
              />
              :
              <>
                {resultsReniec &&
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div
                      onClick={() => createCustomer.mutate({
                        ...resultsReniec,
                        salaId: salaId,
                        document: resultsReniec.dni,
                        birthday: resultsReniec.fechaNac,
                        gender: resultsReniec.gender === 'MASCULINO' ? 'M' : 'F',
                        civil: getEstadoCivil(resultsReniec.estadoCivil),
                        countryId: 173,
                        cellphone: '',
                        email: '',
                      })}
                      key={resultsReniec.id}
                      className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                      style={{width: '100%'}}
                    >
                      {resultsReniec.firstName} {resultsReniec.lastName1} {resultsReniec.lastName2}
                    </div>
                    <div style={{
                      position: 'relative',
                      backgroundColor: '#2196F3',
                      color: '#fff',
                      borderTopRightRadius: '12px',
                      borderBottomRightRadius: '12px',
                      padding: '4px 8px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      display: 'inline-block',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}><span style={{position: 'relative', zIndex: 1}}>Nuevo</span>
                      <div
                        style={{
                          position: 'absolute',
                          left: '-20px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '10px',
                          height: '10px',
                          borderTop: '15px solid transparent',
                          borderBottom: '15px solid transparent',
                          borderRight: '20px solid #2196F3',
                        }}
                      />
                    </div>
                  </div>
                }
                {searchResults.length > 0 ?
                  searchResults.map((customer) => (
                    <div
                      key={customer.id}
                      className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                      onClick={() => handleCustomerSelect(customer)}
                    >
                      {customer.fullTotalName}
                    </div>
                  ))
                  :
                  <>
                    {resultsReniec ?
                      <div></div>
                      :
                      <div className="mx-4 text-base text-center text-neutral-500">
                        {query.length > 2 && searchResults.length === 0 && 'No hay resultados'}
                      </div>
                    }
                  </>
                }
              </>
            }
          </div>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default ComboClient
