import React, {useState} from 'react'
import styles from './dialog-add-card.module.css';
import {Button, Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input} from '../../atoms'
import ComboBank from '../combo-bank';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../../utils/apis';
import {toast} from '../../../hooks/useToast';

const DialogAddCard = ({open, setOpen,selectedCustomer}) => {

  const [cardNumber4, setCardNumber4] = useState('');
  const [bank, setBank] = useState(null);
  const [cardType, setCardType] = useState('VISA');

  const queryClient = useQueryClient();

  const addCardMutation = useMutation({
    mutationKey: ['op_add_card_customer'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/card-history', data),
    onSuccess: () => {
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      setOpen(false)
      setCardNumber4('')
      setBank(null)
      setCardType('VISA')
      queryClient.invalidateQueries({queryKey: ['getUserCards']});
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const addCardCustomerClick = () => {
    if (selectedCustomer?.id) {
      addCardMutation.mutate({
        customerId: selectedCustomer?.id,
        cardType: cardType,
        bankId: bank?.id,
        cardNumber4: cardNumber4
      });
    }
  };

  const getButtonStyle = (buttonCardType) => {
    return cardType === buttonCardType ? styles.selectedButton : styles.buttonGroup;
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogOverlay />
      <DialogContent className='flex flex-col gap-4'>
        <DialogHeader>
          <DialogTitle>Añadir Tarjeta</DialogTitle>
          <DialogDescription>
            Por favor, ingrese el detalle del monto:
          </DialogDescription>
        </DialogHeader>
        <div className='flex flex-col gap-4'>
          <FormControl className='flex-1'>
            <label>Banco</label>
            <ComboBank selectedBank={bank} setSelectedBank={setBank} />
          </FormControl>
          <FormControl>
            <label>Tipo de tarjeta</label>
            <div className='grid items-center grid-cols-2 gap-4'>
              <div className={getButtonStyle('VISA')} onClick={() => setCardType('VISA')}>
                VISA
              </div>
              <div className={getButtonStyle('MASTERCARD')} onClick={() => setCardType('MASTERCARD')}>
                MASTERCARD
              </div>
              <div className={getButtonStyle('DINERS')} onClick={() => setCardType('DINERS')}>
                DINERS CLUB
              </div>
              <div className={getButtonStyle('AMEX')} onClick={() => setCardType('AMEX')}>
                AMEX
              </div>
            </div>
          </FormControl>
          <FormControl className='flex-1'>
            <label>Últimos 4 dígitos</label>
            <Input
              placeholder="Opcional"
              type='number'
              className='text-2xl font-semibold'
              autoComplete="off"
              value={cardNumber4}
              onChange={(e) => setCardNumber4(e.target.value)}
            />
          </FormControl>
          <DialogFooter>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={addCardCustomerClick}>
              Confirmar
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DialogAddCard