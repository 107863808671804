import React, {useContext, useMemo, useState} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO} from 'date-fns';
import {LuCopy} from 'react-icons/lu';
import {BlockLoading, DialogAddClient, EmptyState, ReactTable} from '../../components/blocks';
import {cn, formatNumber} from '../../utils/general';
import {Button} from '../../components/atoms';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {TbGiftCard} from 'react-icons/tb';
import CardSummary from "./hisUtils";
import {toast} from "../../hooks/useToast";
import useEndpoint from "../../hooks/useEndpoint";

const HisSaleTicket = ({restricted = false, promo = false}) => {

  const {fondo, config, printer} = useContext(SiteContext);
  const hisSaleTicket = useEndpoint("hisSaleTicket")

  const [filterState, setFilterState] = useState(null);

  const {data, isFetched, isLoading} = useQuery({
    queryKey: ['getTicketsSaleHistory', fondo?.id],
    queryFn: async () => await axios.get(hisSaleTicket,
      {
        params: {
          validOnly: false,
          fondoId: fondo?.id,
          restricted: restricted
        }
      })
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error fetching tickets sale history', err);
        throw err;
      }),
    enabled: !!fondo?.id
  })

  const retryPrint = (id) => axios.post(`${Apis.CAJA}/operation-ticket-venta/${id}/retry-print`, {
    computerId: printer?.computerId
  });

  const retryModal = useConfirmDialog(
    '¿Deseas imprimir este ticket?',
    'retry_print', //mutateKey
    'getTicketsSaleHistory', //volver a llamar para refrescar la data
    retryPrint // accion para ejecutar en el mutate
  );

  const filteredData = useMemo(() => {
    if (!data?.data) return [];
    if (filterState === null) {
      return data.data;
    }

    if (filterState === 2) {
      return data.data.filter(item => item.fondoIdRedeem !== null || item.redeemLocation === 'CAJA')
    }

    return data.data.filter(item => item.stateCode !== undefined && item.stateCode === filterState);
  }, [filterState, data]);

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copiado",
        variant: 'info',
        duration: 1500
      })
    });
  }

  const columns = useMemo(
    () => [
      {
        header: 'Promo',
        accessorKey: 'promo',
        cell: info => (
          <div className='flex flex-row justify-center'>
            {info.row.original.promo ?
              <TbGiftCard size={20}/>
              :
              '-'
            }
          </div>
        ),
      },
      {
        header: 'Numero de ticket',
        accessorKey: 'vnFull',
        cell: info => (
          <div className='flex flex-row items-center flex-1 gap-2'>
            <span style={{fontFamily: '"Courier New", Courier, monospace'}}>{info.row.original.vnFull}</span>
            <Button
              size='sm'
              variant='outline'
              className='px-2'
              onClick={() => copyClipboard(info.row.original.vnFull)}
            >
              <LuCopy size={16}/>
            </Button>
            <DialogAddClient id={info.row.original.ticketId}/>
          </div>
        ),
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount !== null ? formatNumber(row.amount, '') : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'statusName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },

      },
      {
        header: 'Creación',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Canje',
        id: 'redeemedAt',
        accessorFn: row => `${row.redeemedAt ? format(parseISO(row.redeemedAt), "hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Canjeado en',
        accessorKey: 'redeemLocation',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      // {
      //   header: '',
      //   accessorKey: 'actions',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      //   cell: info => (
      //     <div className='flex flex-row justify-center gap-2'>
      //       {info.row.original.state === 'ERROR' ?
      //         <Button variant='outline' size='sm'
      //                 onClick={() => retryModal.handleClick(info.row.original.id)}>Reimprimir</Button>
      //         :
      //         '--'
      //       }
      //     </div>
      //   ),
      // },
    ]
    , []
  )

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {
        isLoading ? <BlockLoading/> :
          <>
            {(isFetched && data) &&
              <>
                <div className='flex flex-row items-center gap-4'>
                  <CardSummary
                    text='Tickets vendidos'
                    value={data.quantity}
                  />
                  <CardSummary
                    text='Tickets vendidos error'
                    value={data.quantityError}
                  />
                  <CardSummary
                    text='Total'
                    value={formatNumber(data.totalAmount, config.symbol)}
                  />
                </div>

                <div className="flex flex-row gap-2">
                  <Button
                    variant='outline'
                    onClick={() => setFilterState(null)}
                    className={
                      cn('py-1 px-6 text-xs normal-case rounded-xl h-[32px] transition-all',
                        filterState === null ? 'border-transparent bg-neutral-300 text-neutral-950' : 'border-neutral-600')
                    }
                  >
                    Todos
                  </Button>
                  <Button
                    variant='outline'
                    onClick={() => setFilterState(0)}
                    className={
                      cn('py-1 px-6 text-xs normal-case rounded-xl h-[32px] transition-all',
                        filterState === 0 ? 'border-transparent bg-neutral-300 text-neutral-950' : 'border-neutral-600')
                    }
                  >
                    Habilitado
                  </Button>

                  <Button
                    variant='outline'
                    onClick={() => setFilterState(1)}
                    className={
                      cn('py-1 px-6 text-xs normal-case rounded-xl h-[32px] transition-all',
                        filterState === 1 ? 'border-transparent bg-neutral-300 text-neutral-950' : 'border-neutral-600')
                    }
                  >
                    Canjeado
                  </Button>
                  <Button
                    variant='outline'
                    onClick={() => setFilterState(2)}
                    className={
                      cn('py-1 px-6 text-xs normal-case rounded-xl h-[32px] transition-all',
                        filterState === 2 ? 'border-transparent bg-neutral-300 text-neutral-950' : 'border-neutral-600')
                    }
                  >
                    Canjeado en caja
                  </Button>
                </div>
                {filteredData && filteredData.length > 0 ?
                  <ReactTable columns={columns} data={filteredData} initialPageSize={50}/>
                  :
                  <div className="relative w-full h-full overflow-hidden">
                    <EmptyState/>
                  </div>
                }
              </>
            }
          </>
      }
      <retryModal.ConfirmationModal/>
    </div>
  )
}

export default HisSaleTicket
