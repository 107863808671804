import React, {useContext, useMemo} from 'react';
import {formatNumber} from "../../utils/general";
import {EmptyState, ReactTable} from "../../components/blocks";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../utils/apis";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import {format, parseISO} from "date-fns";
import {Button} from "../../components/atoms";
import {SiteContext} from "../../context/SiteContext";
import CardSummary from "./hisUtils";

const HisRefill = () => {

  const {fondo, config} = useContext(SiteContext);

  const {data, isFetched} = useQuery({
    queryKey: ['getRefills', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/refill/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/refill/' + id);

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_refill', //mutateKey
    'getRefills', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm'
                    onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm'>Imprimir</Button>
          </div>
        ),
      }
    ]
    , [deleteModal, config]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      {
        (isFetched && data) &&
        <>
          <div className='flex flex-row items-center gap-4'>
            <CardSummary
              text='Nro de Operaciones'
              value={data.quantity}
            />
            <CardSummary
              text='Total'
              value={formatNumber(data.totalAmount, config.symbol)}
            />
          </div>
          {data?.data && data?.data.length > 0 ?
            <ReactTable columns={columns} data={data?.data}/>
            :
            <div className="relative w-full h-full overflow-hidden">
              <EmptyState/>
            </div>
          }
        </>
      }
      <deleteModal.ConfirmationModal/>
    </div>
  );
};

export default HisRefill;
