import React, {useContext, useEffect, useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import logo from '../../../assets/logo-wargos.png'
import {
  LuAlignJustify,
  LuAlignRight,
  LuArrowLeftRight,
  LuArrowRightLeft,
  LuBanknote,
  LuBinary,
  LuClipboardPaste,
  LuGift,
  LuLogOut,
  LuSearch
} from 'react-icons/lu'
import styles from './sidebar.module.css';
import {cn} from '../../../utils/general';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../../atoms';
import {SiteContext} from '../../../context/SiteContext';
import {TbMoneybag} from 'react-icons/tb';
import {HiOutlineCog} from 'react-icons/hi';
import {HiOutlineSlotMachine} from '../../../assets/icons/slot-machine';
import ProgressBar from "../progress-bar";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../../utils/apis";
import DialogPrebill from "../dialog-prebill";
import {toast} from '../../../hooks/useToast';

const Sidebar = ({logout, open, setOpen}) => {

  const {sala,salaId, userInfo: contextUserInfo, fondo, setFondo, modeLocal, setModeLocal} = useContext(SiteContext)
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    return storedUserInfo && Object.keys(storedUserInfo).length > 0 ? storedUserInfo : contextUserInfo;
  });

  useEffect(() => {
    if (!userInfo || Object.keys(userInfo).length === 0) {
      setUserInfo(contextUserInfo);
    }
  }, [contextUserInfo, userInfo]);

  const [openMap, setOpenMap] = useState(false);
  const [openConfig, setOpeConfig] = useState(false);

  const [switchStateLocal, setSwitchStateLocal] = useState(modeLocal);
  //const [switchResetSaleTicketForm, setSwitchResetSaleTicketForm] = useState(resetSaleTicketForm);

  const handleSwitchChangeLocal = () => {
    const newValue = !switchStateLocal;
    setSwitchStateLocal(newValue);
    setModeLocal(newValue);
    localStorage.setItem('modeLocal', JSON.stringify(newValue));
    toast({
      title: newValue ? 'Modo local activado!' : 'Modo local desactivado!',
      variant: newValue ? 'success':'info',
      duration: 2000
    });
  };

  // const handleSwitchResetSaleForm = () => {
  //   const newValue = !switchResetSaleTicketForm;
  //   setSwitchResetSaleTicketForm(newValue);
  //   setResetSaleTicketForm(newValue);
  //   localStorage.setItem('resetSaleTicketInput', JSON.stringify(newValue));
  //   toast({
  //     title: newValue ? 'Limpieza de formulario de venta de ticket activado!' : 'impieza de formulario de venta de ticket desactivado!',
  //     variant: newValue ? 'success':'info',
  //     duration: 2000
  //   });
  // };

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const handleFondoSelection = () => {
    setFondo(null);
    localStorage.removeItem('fondo');
  };

  const menuItems = [
    {
      title: "Operaciones",
      path: "/",
      icon: <LuBinary size={18} />
    },
    {
      title: "Transferencias",
      path: "transfers",
      icon: <LuArrowLeftRight size={18} />
    },
    {
      title: "Billetero",
      path: "bill",
      icon: <LuBanknote size={18} />
    },
    // {
    //   title: "Historial",
    //   path: "historial",
    //   icon: <LuHistory size={16} />
    // },
    {
      title: "Consulta ticket",
      path: "ticket-review",
      icon: <LuSearch size={18} />
    },
    {
      title: "Tickets emitidos",
      path: "ticket-machine",
      icon: <HiOutlineSlotMachine size={18} />
    },
    {
      title: "Canjes",
      path: "cashback",
      icon: <LuGift size={18} />
    },
    {
      title: "Cerrar turno",
      path: "close-turn",
      icon: <LuClipboardPaste size={18} />
    }
  ]

  const {data: barra} = useQuery({
    queryKey: ['getMeterOnline'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        }
      };
      const response = await axios.get(Apis.TGM + '/machines/meter-online', config);
      return response.data;
    },
    gcTime: 1,
    refetchInterval: 5000
  });

  const [openPrebills, setOpenPrebills] = useState([]);

  const {data: prebill} = useQuery({
    queryKey: ['getBillOpen'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        }
      };
      const response = await axios.get(Apis.TGM + '/stacker-alert', config);
      setOpenPrebills(Array(response.data.length).fill(true));
      return response.data;
    },
    gcTime: 1,
    refetchInterval: 10000,
    onSuccess: (data) => {
      setOpenPrebills(Array(data.length).fill(true));
    }
  });

  const toggleDialog = (index) => {
    setOpenPrebills((prev) =>
      prev.map((open, i) => (i === index ? !open : open))
    );
  };

  const enableStackerAlert = () => {
    return (salaId === '24' || salaId === '999');
  }

  const token = JSON.parse(localStorage.getItem("currentUser")).token;
  const layoutUrl = `https://mapas.wargos.cloud/home?salaId=${salaId}&id_token=${token}&view=2`;

  return (
    <div className={cn(styles.container, open ? styles["open"] : '')}>

      <div className={styles.headerContainer}>
        <div className={cn(styles.headerTitle, open ? styles["open"] : '')}>
          <div className={styles.containerLogo}>
            <img src={logo} alt='logo' />
          </div>
          <span className='text-[20px] font-semibold'>Wargos</span>
        </div>

        <div onClick={toggleSidebar} className={styles.headerToggle}>
          {open ? <LuAlignJustify size={24} /> : <LuAlignRight size={24} />}
        </div>
      </div>

      <div className='flex flex-col items-start self-stretch flex-1'>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {open ?
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <NavLink className={styles.menuItem} to={item.path}>
                      <div className={styles.menuItemIcon}>
                        {item.icon}
                      </div>
                      <span className={cn(styles.menuItemTitle, open ? styles["open"] : '')}>{item.title}</span>
                    </NavLink>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={8} side="right">
                    <p>{item.title}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              :
              <NavLink className={styles.menuItem} to={item.path}>
                <div className={styles.menuItemIcon}>
                  {item.icon}
                </div>
                <span className={cn(styles.menuItemTitle, open ? styles["open"] : '')}>{item.title}</span>
              </NavLink>
            }
          </React.Fragment>
        ))}
      </div>

      <div className='cursor-pointer' onClick={() => setOpenMap(true)}>
        <ProgressBar label="Conex. con máquina" subLabel="máq." value={barra && barra.machineLink} total={barra && barra.total}
                     isSidebarCollapsed={open}/>
        {/*<ProgressBar label="Conex con servidor" subLabel="srv." value={barra && barra.serverLink} total={barra && barra.total}*/}
        {/*             isSidebarCollapsed={open} onClick={() => setOpenMap(true)}/>*/}
      </div>

      <Dialog open={openMap} onOpenChange={setOpenMap}>
        <DialogOverlay/>
        <DialogContent className="flex flex-col gap-3 max-w-7xl h-5/6">
          <iframe src={layoutUrl} title="Mapa" width="100%" height="100%" className='border-0' />
        </DialogContent>
      </Dialog>

      {fondo && enableStackerAlert() && prebill?.length > 0 && prebill.map((item, index) => (
        <DialogPrebill
          key={index}
          open={openPrebills[index]}
          setOpen={() => toggleDialog(index)}
          data={item}
        />
      ))}

      <div className='flex flex-col gap-2 pt-4 border-t border-neutral-800'>
        {open ?
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button className='justify-center h-10 p-0 border-neutral-900' onClick={() => handleFondoSelection()}>
                  <TbMoneybag size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>{fondo?.name}</p>
              </TooltipContent>
            </Tooltip>
            
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button variant='outline' className='justify-center h-10 p-0' onClick={() => navigate('selector')}>
                  <LuArrowRightLeft size={16} />
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>Cambiar sala</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div
                  className='flex items-center justify-center w-10 h-10 font-bold cursor-pointer min-w-10 rounded-2xl hover:bg-white/10'
                  onClick={logout}
                >
                  <LuLogOut size={16} />
                </div>
              </TooltipTrigger>
              <TooltipContent sideOffset={8} side="right">
                <p>Salir</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          :
          <>
            <Button className='justify-start h-12 px-3 bg-amber-300' onClick={() => handleFondoSelection()}>
              <TbMoneybag size={18}/>
              <span className='flex-1 text-sm text-left capitalize'>
                {fondo?.name}
              </span>
              <LuArrowRightLeft size={16}/>
            </Button>
            <div className='flex flex-row gap-2'>
              <Button variant='outline' className='justify-start flex-1 h-12 px-3 border-neutral-900' onClick={() => navigate('selector')}>
                <LuArrowRightLeft size={18} />
                <div className='flex flex-col flex-1 w-[115px]'>
                  <span className='overflow-hidden text-sm text-left capitalize text-ellipsis text-nowrap'>
                    {sala?.name}
                  </span>
                </div>
              </Button>
              <Button variant='outline' className='w-12 h-12 p-0 border-neutral-900' onClick={() => setOpeConfig(true)}>
                <HiOutlineCog size={20}/>
              </Button>
            </div>
            <div className='flex flex-row items-center gap-2 p-2 rounded-2xl bg-neutral-900 hover:bg-neutral-800'>
              <div className={styles.avatar}>
                {`${userInfo?.user?.name.charAt(0)}${userInfo?.user?.lastName.charAt(0)}`}
              </div>
              <div className='flex flex-col flex-1 w-[116px]'>
                <span className='overflow-hidden text-sm uppercase text-ellipsis text-nowrap'>
                  {userInfo?.user?.name + ' ' + userInfo?.user?.lastName}
                </span>
              </div>
              <div
                className='flex items-center justify-center font-bold cursor-pointer w-9 h-9 min-w-9 rounded-2xl'
                onClick={logout}
              >
                <LuLogOut size={16} />
              </div>
            </div>
          </>
        }
      </div>

      <Dialog open={openConfig} onOpenChange={setOpeConfig}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Configuración</DialogTitle>
            <DialogDescription>
              Configuración adicional de caja
            </DialogDescription>
          </DialogHeader>
          <div className='flex flex-col gap-4 mt-4'>
            <FormControl className='flex flex-row gap-2'>
              <Switch
                id="local-mode"
                checked={modeLocal}
                onCheckedChange={handleSwitchChangeLocal}
              />
              <label htmlFor="local-mode">Modo Local</label>
            </FormControl>
            {/*<FormControl className='flex flex-row gap-2'>*/}
            {/*  <Switch*/}
            {/*    id="resetSaleTicketForm"*/}
            {/*    checked={resetSaleTicketForm}*/}
            {/*    onCheckedChange={handleSwitchResetSaleForm}*/}
            {/*  />*/}
            {/*  <label htmlFor="resetSaleTicketForm">Limpiar monto de ticket luego de vender</label>*/}
            {/*</FormControl>*/}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Sidebar
