import React, {useContext, useEffect, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {Apis} from '../../../utils/apis'
import {SiteContext} from '../../../context/SiteContext'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../atoms'
import BlockLoading from '../block-loading'
import {LuUsers2} from 'react-icons/lu'
import {useNavigate} from 'react-router-dom'
import {cn, formatNumber} from '../../../utils/general'
import {toast} from '../../../hooks/useToast'
import DialogConfirm from '../dialog-confirm'
import { queryClient } from '../../..'


const DialogTurns = () => {

  const {fondo, setFondo, salaId, printer, setPrinter, config} = useContext(SiteContext);
  const [open, setOpen] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [createSuccess, setCreateSuccess] = useState(false);
  const [selectedFondoId, setSelectedFondoId] = useState('');
  const [iniAmount, setIniAmount] = useState('');

  const {data: allFondos = []} = useQuery({
    queryKey: ['getAllFondos'],
    queryFn: async () => await axios.get(Apis.CAJA + '/fund-type?type='+2)
      .then((res) => res.data)
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (fondo && printer) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [fondo, printer]);

  const {data: fondos = [], isLoading} = useQuery({
    queryKey: ['getCashierTurn'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        }
      };
      const response = await axios.get(Apis.CAJA + '/fondos/by-user?active=true', config);
      return response.data;
    }
  })

  const {data: impresoras = [], isLoading: isLoadingPrinters} = useQuery({
    queryKey: ['getPrinters'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        },
        params : {
          enabled: true,
          accountId : salaId
        }
      };
      const response = await axios.get(Apis.PRINT + '/printers/state-report', config);
      return response.data;
    }
  })

  const bankMutation = useMutation({
    mutationKey: ['op_get_banks'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/bank', data),
    onSuccess: (response) => {
      localStorage.setItem('banks', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const expensesCategoryMutation = useMutation({
    mutationKey: ['op_get_expenses_category'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/expenses-category', data),
    onSuccess: (response) => {
      localStorage.setItem('expenses', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleFondoSelection = (selectedFondo) => {
    setFondo(selectedFondo);
    localStorage.setItem('fondo', JSON.stringify(selectedFondo));
    bankMutation.mutate();
    expensesCategoryMutation.mutate();
  };

  const handlePrinterSelection = (selectedPrinter) => {
    setPrinter(selectedPrinter);
    localStorage.setItem('printer', JSON.stringify(selectedPrinter));
  };

  const createMutation = useMutation({
    mutationKey: ['createTurn'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos', data),
    onSuccess: () => {
      setCreateSuccess(true)
      queryClient.invalidateQueries({queryKey: ['getCashierTurn']});
      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
      setConfirmOpen(false)
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = () => {
    createMutation.mutate({
      iniAmount: iniAmount,
      fundTypeId: selectedFondoId,
    });
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const selected = allFondos.find(fondo => fondo.id === selectedFondoId);

  return (
    <>
      <Dialog open={open} onOpenChange={(isOpen) => {
        if (fondo && printer) {
          setOpen(isOpen);
        } else {
          setOpen(true);
        }
      }}>
        <DialogOverlay />
        <DialogContent
          className='flex flex-col gap-4'
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{config?.onlyCashier ? 'Tus fondos asignados' : 'Fondos'}</DialogTitle>
            {fondos.length > 0 &&
              <DialogDescription>
                Selecciona un fondo e impresora para poder continuar:
              </DialogDescription>
            }
          </DialogHeader>
          {isLoading && isLoadingPrinters ? <BlockLoading /> : (
            fondos.length === 0 ?
              <div>
                {config?.onlyCashier && !createSuccess ?
                  <div className="flex flex-col gap-6 px-2 mt-4">
                    <FormControl>
                      <label>Seleccione la posicion</label>
                      <Select onValueChange={setSelectedFondoId} value={selectedFondoId}>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Seleccione posicion" />
                        </SelectTrigger>
                        <SelectContent>
                          {allFondos.map((fondo) => (
                            <SelectItem key={fondo.id} value={fondo.id}>
                              {fondo.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <label>Ingrese el monto Inicial</label>
                      <Input
                        type="number"
                        value={iniAmount}
                        onChange={(e) => setIniAmount(e.target.value)}
                        placeholder="Monto"
                        className='h-10 text-base placeholder:text-base'
                      />
                    </FormControl>
                    <div className='flex flex-row items-center justify-end gap-2'>
                      <Button variant='outline' onClick={() => navigate('/selector')}>Cancelar</Button>
                      <Button onClick={() => setConfirmOpen(true)}>Aperturar</Button>
                    </div>
                  </div>
                  :
                  <div className="flex flex-col items-center gap-6 mt-4 text-center">
                    <span>Por el momento no hay fondos disponibles. Por favor, contacta con el supervisor.</span>
                    <LuUsers2 size={36} />
                    <Button onClick={() => navigate('/selector')}>Aceptar</Button>
                  </div>
                }
              </div>
              :
              <div className='flex flex-col gap-6'>
                <div className={cn('grid gap-4', fondos.length === 1 ? 'grid-cols-1' : 'grid-cols-2')}>
                  {fondos.map((turn) => (
                    <div
                      key={turn.id}
                      className={cn(
                        'flex flex-col items-center justify-center gap-1 p-4 border cursor-pointer rounded-2xl hover:bg-neutral-900',
                        fondo?.id === turn.id ? 'bg-white text-neutral-950 border-neutral-300  hover:bg-neutral-100' : 'border-neutral-500'
                      )} onClick={() => handleFondoSelection(turn)}
                    >
                      <span>{turn?.name}</span>
                      <span className='text-sm text-neutral-500'>Periodo: {turn?.period}</span>
                    </div>
                  ))}
                </div>
                <FormControl>
                  <label>Seleccione Impresora</label>
                  <Select onValueChange={(value) => handlePrinterSelection(impresoras.find(printer => printer.printerId === value))} value={printer?.printerId || ''}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Seleccione impresora" />
                    </SelectTrigger>
                    <SelectContent>
                      {impresoras.map((printer) => (
                        <SelectItem key={printer.printerId} value={printer.printerId}>
                          {printer.printerName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </div>
          )}
        </DialogContent>
      </Dialog>
      <DialogConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
        disabled={createMutation.isPending}
        body={
          <div className='flex flex-col gap-4 my-4'>
            <span className='mb-4 text-neutral-500'>¿Los datos ingresados son correctos?</span>
            <div className='flex flex-row gap-2'>
              <span>Fondo seleccionado:</span>
              <span className='font-bold'>{selected?.name}</span>
            </div>
            <div className='flex flex-row items-end gap-3'>
              <span>Monto inicial:</span>
              <span className='text-xl font-bold'>{formatNumber(iniAmount, config.symbol)}</span>
            </div>
          </div>
        }
      />
    </>
  )
}

export default DialogTurns
